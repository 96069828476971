import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Main from "../components/main";

export default () => (
  <div>
  <Header />
  <Main />
  <Footer />
</div>
)
